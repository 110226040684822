"use client";

import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronUp, Settings } from "lucide-react";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import "@/styles/nav.scss";

const pages = [
  { name: "teams", link: "/team" },
  { name: "projects", link: "/projects" },
  { name: "about", link: "/about" },
];

export default function Navbar() {
  const [showNav, setShowNav] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [homeHover, setHomeHover] = useState(false);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();

    if (latest > previous && latest > 150) {
      setHidden(true);
      setShowNav(false);
    } else {
      setHidden(false);
    }
  });

  useEffect(() => {
    const media = window.matchMedia("(min-width: 640px)");
    media.addEventListener("change", (e) => {
      if (e.matches) {
        setShowNav(false);
      }
    });
  }, []);

  return (
    <motion.nav
      className={`fixed inset-0 top-4 w-[95%] sm:w-[90%] mx-auto font-medium text-slate-50 flex  max-sm:justify-between gap-4 px-3 max-w-7xl items-center rounded-full font-mono h-14 p-5 overflow-hidden border-white border-[1px] border-solid bg-black z-50`}
      variants={{
        long: { maxWidth: 950 },
        short: { maxWidth: 280 },
        hideNav: {
          height: 56,
          borderRadius: 50,

          alignItems: "center",
          transition: { delay: 0, duration: 0.3 },
        },
        showNav: {
          height: 160,
          borderRadius: 22,
          alignItems: "start",
          transition: { delay: 0 },
        },
      }}
      initial={"short"}
      animate={[hidden ? "short" : "long", showNav ? "showNav" : "hideNav"]}
      transition={{
        duration: 0.6,
        type: "spring",
        stiffness: 80,
        damping: 14,
      }}
    >
      <motion.div
        className="min-w-[40px] min-h-[40px] rounded-full gap-2 bg-slate-50 flex items-center justify-center"
        onMouseOver={() => setHomeHover(true)}
        onFocus={() => setHomeHover(true)}
        onBlur={() => setHomeHover(false)}
        onMouseLeave={() => setHomeHover(false)}
        whileHover={{ rotate: -30 }}
      >
        <a href="/">
          {/* <img src="/images/logo.svg" alt="logo" className="w-8 h-8" /> */}
          <Settings color={homeHover ? "red" : "black"} />
        </a>
      </motion.div>
      <motion.ul
        className={`w-full ${
          showNav
            ? "[--display-from:none] [--display-to:flex]"
            : "max-sm:[--display-from:none] sm:[--display-to:flex]"
        }  [--opacity-from:0.1] [--opacity-to:1] flex-col sm:flex-row items-center justify-center gap-10 max-sm:gap-5 max-sm:pt-10`}
        variants={{
          hidden: {
            display: "var(--display-from, none)",
            opacity: "var(--opacity-from, 1)",
            transition: { duration: 0.6, delay: 0 },
          },
          visible: {
            display: "var(--display-to, none)",
            opacity: "var(--opacity-to, 1)",
            transition: { duration: 0.6, delay: 0 },
          },
        }}
        initial={"hidden"}
        animate={[
          hidden && !showNav ? "hidden" : "visible",
          showNav ? "visible" : "",
        ]}
      >
        {pages.map((page) => (
          <li key={page.link}>
            <a href={page.link}>/{page.name}</a>
          </li>
        ))}
      </motion.ul>
      <Button
        size={"icon"}
        variant={"ghost"}
        className="rounded-full min-w-[40px] sm:hidden"
        onClick={() => {
          setHidden(false);
          setShowNav((prev) => !prev);
        }}
      >
        {showNav ? <ChevronUp /> : <ChevronDown />}
      </Button>
    </motion.nav>
  );
}
